<template>
  <div class="hx-page">
    <div>
      <el-form :model="formData" ref="formData" label-width="120px">
        <div class="box-title">运单标识 - 手机号每周最大使用次数</div>
        <div v-for="item in formData.type2" :key="item.id">
          <el-col :span="8">
            <el-form-item 
            :label="item.key">
              <el-input placeholder="（0为不限制）" v-model.number="item.value" clearable="">
                <template slot="append">次</template>
              </el-input>
            </el-form-item>
          </el-col>
        </div>
        <div class="box-title">运单标识 - 证件号每周最大使用次数</div>
        <div v-for="item in formData.type3" :key="item.id">
          <el-col :span="8">
            <el-form-item 
            :label="item.key">
              <el-input placeholder="（0为不限制）" v-model.number="item.value" clearable="">
                <template slot="append">次</template>
              </el-input>
            </el-form-item>
          </el-col>
        </div>
        <div class="box-title">运单标识 - 手机号每月最大使用次数</div>
        <div v-for="item in formData.type4" :key="item.id">
          <el-col :span="8">
            <el-form-item 
            :label="item.key">
              <el-input placeholder="（0为不限制）" v-model.number="item.value" clearable="">
                <template slot="append">次</template>
              </el-input>
            </el-form-item>
          </el-col>
        </div>
        <div class="box-title">运单标识 - 证件号每月最大使用次数</div>
        <div v-for="item in formData.type5" :key="item.id">
          <el-col :span="8">
            <el-form-item 
            :label="item.key">
              <el-input placeholder="（0为不限制）" v-model.number="item.value" clearable="">
                <template slot="append">次</template>
              </el-input>
            </el-form-item>
          </el-col>
        </div>
      </el-form>
      <div class="buts">
        <el-button type="primary" @click="handleSubmit">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import settingHelper from 'api/setting.js'
import { GetToken } from 'utils/auth'
export default {
  components: {},
  name: '',
  data() {
    return {
      formData:{
        type1:[],
        type2:[],
        type3:[],
        type4:[],
        type5:[]
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getSettings()
  },
  mounted() {},
  destroyed() {},
  methods: {
    getSettings(){
      settingHelper.getAgencyLogisticsSetting({
        token: GetToken(),
      }).then(res => {
        if (res.success) {
          let data = res.record.sort((a,b) => {
            return a.type - b.type
          })
          this.formData.type2  = data.filter(item => {
            item.value = +item.value
            return item.type == 6
          })
          this.formData.type3  = data.filter(item => {
            item.value = +item.value
            return item.type == 7
          })
          this.formData.type4  = data.filter(item => {
            item.value = +item.value
            return item.type == 8
          })
          this.formData.type5  = data.filter(item => {
            item.value = +item.value
            return item.type == 9
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleSubmit(){
      let data = this.formData.type2.concat(this.formData.type3,this.formData.type4,this.formData.type5)
      const regex = /^\d+$/
      data = data.map(item => {
        if(!regex.test(item.value)){
          item.value = 0
        }
        if(!regex.test(item.day)){
          item.day = 0
        }
        return item
      })
      settingHelper.setAgencyLogisticsSettings({
        token: GetToken(),
        setting:JSON.stringify(data)
      }).then(res => {
        if (res.success) {
          this.$message.success('修改成功！')
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hx-page{
  padding:10px;
  .box-title{
    font-size: 18px;
    margin: 10px 0;
  }
  .buts{
    padding: 10px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
